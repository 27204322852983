<template>
  <BFormGroup
    class="RadioChecklistItem"
    :label="label"
    :label-for="`input-group-${name}-${$_uid}`"
    label-cols-sm="9"
  >
    <template #label>
      <slot name="label" />
    </template>
    <BFormRadioGroup
      :id="`input-group-${name}-${$_uid}`"
      class="RadioChecklistItem__radioGroup"
      :name="name"
      :value="value"
      @input="updateValue($event)"
    >
      <BFormRadio :value="true">
        Ja
      </BFormRadio>
      <BFormRadio :value="false">
        Nei
      </BFormRadio>
    </BFormRadioGroup>
  </BFormGroup>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    name: {
      type: [String, Number],
      required: true,
    },
    value: {
      type: [Boolean, null],
      default: null,
    },
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
  },
}
</script>
