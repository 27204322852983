<template>
  <div class="FloorPlan">
    <BImgLazy
      fluid
      :src="`https://${cdnUrl}/media/fill/1140x1140/255,255,255/${image}`"
    />
    <div
      v-if="marker !== null"
      class="FloorPlan__markerContainer"
    >
      <FontAwesomeIcon
        :icon="['fas', 'times-square']"
        class="FloorPlan__marker"
        :style="markerStyle"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    marker: {
      type: Object,
      default: null,
    },
  },

  computed: {
    cdnUrl () {
      return this.$store.state.site.cdnUrl
    },

    markerStyle () {
      return {
        left: `${this.marker.x}%`,
        top: `${this.marker.y}%`,
      }
    },
  },
}
</script>
