<template>
  <BCard
    no-body
    class="IssueMessageCard"
  >
    <BCardHeader>
      <template v-if="message.writtenBy === 'Vestaksen'">
        <span class="IssueMessageCard__recipient">
          <FontAwesomeIcon
            :icon="['fas', 'inbox-out']"
            class="mr-2"
          />
          Utgående melding til {{ message.sentToName }} &lt;{{ message.sentToMail }}&gt;
        </span>
      </template>
      <template v-else>
        <span class="IssueMessageCard__sender">
          <FontAwesomeIcon
            :icon="['fas', 'inbox-in']"
            class="mr-2"
          />
          Innkommende melding fra
          <FontAwesomeIcon
            :icon="['fas', 'user']"
            class="ml-2"
          />
          {{ message.writtenBy }}
        </span>
      </template>
    </BCardHeader>

    <BCardBody>
      <div v-html="message.message" />
    </BCardBody>

    <BCardFooter>
      <span
        v-b-tooltip.hover
        class="IssueMessageCard__timestamp"
        :title="date"
      >
        {{ relativeTime }}
      </span>
    </BCardFooter>
  </BCard>
</template>

<script>
import Dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/nb'

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    date () {
      return Dayjs(this.message.created).format('DD/MM/YYYY HH:mm')
    },
    relativeTime () {
      Dayjs.locale('nb')
      Dayjs.extend(RelativeTime)

      return Dayjs(this.message.created).fromNow()
    },
  },
}
</script>
