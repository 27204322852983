import Vue from 'vue'

const loginPageMountElement = document.querySelector('.LoginPage')

if (loginPageMountElement) {
  new Vue({
    name: 'LoginPageRoot',

    mounted () {
      const urlParams = new URLSearchParams(window.location.search)

      if (urlParams.has('logout')) {
        this.$bvToast.toast('Du har blitt logget ut.', {
          variant: 'success',
          solid: true,
          title: 'Logget ut',
          toaster: 'b-toaster-top-center',
        })
      }
    },
  }).$mount(loginPageMountElement)
}
