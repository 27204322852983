import Vue from 'vue'
import Axios from 'axios'

import DetailsList from '@/shared/components/DetailsList'
import DetailsListItem from '@/shared/components/DetailsListItem'

Vue.component('details-list', DetailsList)
Vue.component('details-list-item', DetailsListItem)

const addAssetsPageMountElement = document.querySelector('.AddAssetsPage')

if (addAssetsPageMountElement) {
  new Vue({
    name: 'AddAssetsPageRoot',

    data: () => ({
      formDefault: {
        images: [],
      },
      form: {},
      isSubmitting: false,
      submitSuccessful: false,
    }),

    mounted () {
      this.form = this.formDefault
    },

    methods: {
      uploadImages (e) {
        this.isSubmitting = true

        const issue = e.target.dataset.issue

        const formData = new FormData()

        if (this.form.images.length > 0) {
          this.form.images.forEach((image, index) => {
            formData.append(`files[${index}]`, image)
          })
        }

        Axios.post(`/api/issues/${issue}/uploadAssets/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          this.isSubmitting = false
          this.submitSuccessful = true
          this.clearForm()
        }).catch(error => {
          this.isSubmitting = false

          this.$bvToast.toast('Bildene ble ikke lastet opp.', {
            variant: 'danger',
            title: 'Noe gikk galt',
          })

          console.error(error)
        })
      },

      formatFileNames (files) {
        if (files.length === 1) {
          return files[0].name
        } else {
          return `${files.length} filer valgt`
        }
      },

      clearForm () {
        this.form = this.formDefault
      },
    },
  }).$mount(addAssetsPageMountElement)
}
