<template>
  <b-list-group-item class="DetailsListItem">
    <div
      v-if="icon !== null"
      class="DetailsListItem__icon"
    >
      <FontAwesomeIcon
        :icon="icon"
      />
    </div>

    <div class="DetailsListItem__title">
      {{ title }}
    </div>

    <div class="DetailsListItem__value">
      <template v-if="value">
        {{ value }}
      </template>
      <slot v-else />
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: [Array, null],
      default: null,
    },
  },
}
</script>
